import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
    <Navbar />
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
      <div className="bg-white rounded border shadow mt-10 p-6">
        <h1 className="text-2xl text-gray-800 font-semibold py-1">Contact Information</h1>
        <hr className="py-1"/>
        <p className="text-gray-700 text-sm">We look forward to receiving messages from our users and possible business partners.
          Below is our contact information:</p>
        <p className="text-gray-700 text-sm">
          Bluefin Enterprises Inc.
        </p>
        <p className="text-gray-700 text-sm">
          2050 River Road West
        </p>
        <p className="text-gray-700 text-sm">Wasaga Beach, Ontario, L9Z 2W1, Canada</p>
        <p className="text-gray-700 text-sm">sales@bluefinenterprises.com</p>
        <p className="text-gray-700 text-sm">(844) 334-1515</p>
      </div>
    </div>
    <Footer />
  </>
)